import { useState } from 'react';
import { Box, Button } from '@hyphen/hyphen-components';
import { Guide } from '../../../components/guide/Guide';
import { GuideStep } from '../../../components/guide/GuideStep';
import MarkdownRenderer from '../../../components/common/MarkdownRenderer';

enum ToggleSdk {
  NodeJS = 'NodeJS',
  React = 'React',
  JavaScript = 'JavaScript (Web)',
  DotNet = '.NET',
  Go = 'Go',
  Python = 'Python',
}

const sdkKeys = Object.keys(ToggleSdk) as Array<keyof typeof ToggleSdk>;
const sdkPaths = sdkKeys.map((key) => `/docs/${key.toLowerCase()}.md`);

export const FeatureToggleGuide = () => {
  const [selectedSdk, setSelectedSdk] = useState<keyof typeof ToggleSdk | undefined>(undefined);

  return (
    <Box
      width="100"
      gap="2xl"
      padding="4xl 4xl 5xl 4xl"
      fontSize="sm"
      style={{
        transition: 'width 0.3s ease-in-out',
      }}
    >
      <Guide title={selectedSdk ? 'Change SDK' : 'Select your SDK'}>
        <GuideStep
          title={
            <Box alignItems="flex-start" gap="xl">
              Select your SDK
              {selectedSdk && (
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => {
                    setSelectedSdk(undefined);
                  }}
                >
                  Change SDK
                </Button>
              )}
            </Box>
          }
        >
          <Box direction="row" gap="lg" wrap>
            {sdkKeys
              .filter((sdk) => !selectedSdk || sdk === selectedSdk)
              .map((sdk) => (
                <SdkItem key={sdk} name={sdk} displayName={ToggleSdk[sdk]} onSelect={() => setSelectedSdk(sdk)} />
              ))}
          </Box>
        </GuideStep>
        {selectedSdk ? (
          <Box as="li" gap="md" direction="column" className="markdown-renderer">
            <MarkdownRenderer filePath={sdkPaths[sdkKeys.indexOf(selectedSdk)]} />
          </Box>
        ) : (
          <GuideStep title="Select an SDK for next steps..." />
        )}
      </Guide>
    </Box>

  );
};



const SdkItem = ({
  name,
  displayName,
  onSelect,
}: {
  name: keyof typeof ToggleSdk;
  displayName: string;
  onSelect: () => void;
}) => (
  <Box
    alignItems="center"
    gap="lg"
    as="button"
    borderWidth="0"
    background="transparent"
    padding="0"
    cursor="pointer"
    onClick={onSelect}
    color="base"
  >
    <Box
      width="5xl"
      height="5xl"
      borderColor="subtle"
      borderWidth="sm"
      radius="sm"
      background="primary"
      hover={{ borderColor: 'brand-dark-grey' }}
      padding="md"
    >
      <img src={`/images/${name.toLowerCase()}.svg`} alt={`${displayName} logo`} style={{ width: '100%', height: '100%' }} />
    </Box>
    <Box fontSize="xs">{displayName}</Box>
  </Box>
);
